.reviewsSectionContainer {
  border-top: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  padding: 27px 6px;

  @media (max-width: 767px) {
    padding: 27px 19px;
  }

  .writeReviewBtn {
    width: 130px;
    height: 41px;
    border: 1px solid #5c3cb7;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: #5b3bb7;
    cursor: pointer;
  }

  .customerReviewReplyContainer {
    padding: 30px 10px 24px 4px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e2e2e2;

    p {
      font-size: 14px;
    }

    img {
      width: 14px;
      height: 14px;
    }

    .readMore {
      color: #28292c;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    @media (max-width: 767px) {
      p {
        font-size: 12px;
      }

      img {
        width: 12px;
        height: 12px;
      }

      .readMore {
        color: #623abc;
      }
    }

    .replyContainer {
      display: flex;
      margin-top: 12px;
      flex-direction: column;
      align-items: flex-start;
      background-color: #f7f7f7;
      padding: 16px;
      border-radius: 6px;

      p {
        font-size: 14px;
        font-weight: 400;
        color: #28292c;

        span {
          font-weight: 500;
          font-size: 14px;
          color: #28292c;
        }
      }

      @media (max-width: 767px) {
        p {
          font-size: 12px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}

.verifiedBuyerModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.okBtnContainer {
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: 14px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border: 0;
  }
}
