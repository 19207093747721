.modal {
  padding-top: 16px;
  padding-left: 6px;
  position: relative;
  .gap {
    display: flex;
    gap: 5px;
  }

  .crossIcon {
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
  }

  input {
    padding-left: 18px;
    border: 1px solid #dededf;
    border-radius: 8px;
    height: 48px;
    width: 239px;
  }

  textarea {
    resize: none;
    border-radius: 8px;
    border: 1px solid #dededf;
    padding: 12px;
    width: 475px;
    height: 92px;
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
    margin-right: 7px;

    .cancelBtn {
      background-color: #fff;
      border: 0;
      color: #28292c;
      font-weight: 600;
      font-size: 14px;
      margin-right: 30px;
      cursor: pointer;
    }

    .submitBtn {
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      border: 0;
      width: 103px;
      height: 41px;
      cursor: pointer;
    }
  }
}

.mobileModal {
  .header {
    height: 53px;
    width: 100%;
    display: flex;
    padding-left: 20px;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  section {
    padding: 0px 20px;
  }

  .gap {
    display: flex;
    gap: 8px;
  }

  input {
    border-radius: 8px;
    height: 48px;
    border: 1px solid #dededf;
    padding: 12px;
    width: 188px;
    margin-bottom: 20px;
  }

  textarea {
    resize: none;
    border-radius: 8px;
    border: 1px solid #dededf;
    padding: 12px;
    width: 100%;
    height: 113px;
  }

  button {
    margin: 30px 0;
    height: 51px;
    width: 100%;
    background-color: #996fd5;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border: 0;
  }
}
